var vShop = {
	name : "vShop",
	props: ['shop'],
	template: `<li class="a">
					<a :href="shop.link" :title="shop.name | removeHTML"><div class="img"><figure :style="{ 'background-image': 'url( ' + shop.thumbnail + ' )' }"></figure></div></a>
					<div class="txt">
						<a :href="shop.link" :title="shop.name">
							<h3>{{shop.name}}</h3>
							<p class="genre">{{shop.subcategory}}</p>
							<p class="category">{{shop.category}}</p>
						</a>
						<a class="btn_star" href="javascript:void(0);" title="STAR" v-on:click="toggleStar(shop.id)" :data-id="shop.id"><span>STAR</span></a>
					</div>
				</li>`,
	filters: {
		removeHTML: function(value){
			return value.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'')
		},
		makelist: function (value){
			let genres = '';
			for(var i = 0; i < value.length; i++){
				genres += `<span>${value[i].name}</span>`;
			}
			return genres;
		},
		checkSubcategory: function(value){
			let subcategory = "";
			if(!value){
				
			}else{
				subcategory = `<p class="genre"></p>`;
			}
			return subcategory;
		}
	},
	methods: {
		toggleStar: function(value){
			let $this = $('a.btn_star[data-id="' + value + '"]');
			if( $this.hasClass("active") ){
				$this.removeClass("active");
				star.removeStar(value);
			}else{
				$this.addClass("active");
				star.checkStar(value);
			}
		}
	}
}
