var vShopGenres = {
	name : "vShopGenres",
	props: ['genre'],
	template: 	`<li><a href="javascript:void(0);" :title=genre.name :data-id=genre.id><span>{{genre.name}}</span></a></li>`,
	methods: {
		
	}
}
var vShopAreas = {
	name : "vShopAreas",
	props: ['area'],
	template: 	`<li><a href="javascript:void(0);" :title=area.name :data-id=area.id><span>{{area.name}}</span></a></li>`,
	methods: {
		
	}
}
var vInitials = {
	name : "vInitials",
	props: ['initial'],
	template: 	`<li><a href="javascript:void(0);" :title=initial.name :data-initial=initial.id><span>{{initial.name}}</span></a></li>`,
	methods: {
		
	}
}