var vEvents = {
	name : "vEvents",
	props: ['event'],
	template: 	`<li :class="event.extarnal | checkIsInvison">
					<a :href=event.link :title="event.name | removeHTML">
						<div class="place">
							<p>{{event.area | checkArea}}</p>
						</div>
						<div class="content">
							<div class="img"><figure :style="{ 'background-image': 'url( ' + checkImage( event.thumbnail ) + ' )' }"></figure></div>
							<div class="txt">
								<div class="name">
									<p class="date">{{event.event_start + "," +  event.event_end | returnDate}}</p>
									<h4 v-html="event.name"></h4>
								</div>
								<p class="description" v-html="event.description"></p>
							</div>
						</div>
					</a>
				</li>`,
	filters: {
		removeHTML: function(value){
			return value.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'')
		},
		checkArea: function(value){
			if(value === "null" || !value){
				return "";
			}else{
				return value;
			}
		},
		returnDate: function(value){
			let dates = value.split(",");
			let start = dates[0];
			let end = dates[1];
			let startY = start.split("-")[0];
			let endY = end.split("-")[0];
			let endArry = end.split("-");
			if(start === end){
				let date = start.split("-").join("/") + "〜";
				return date;
			}else{
				if(startY === endY){
					return start.split("-").join("/") + "〜" + endArry[1] + "/" + endArry[2];
				}else{
					//console.log(start, end);
					if(end == null || end === "null"){
						return start.split("-").join("/") + "〜";
					}else{
						return start.split("-").join("/") + "〜" + end.split("-").join("/");
					}
				}
			}
		},
		checkIsInvison: function(value){
			if(value === 0){
				return "invison";
			}
		}
	},
	computed: {
		thumbnailSRC(){
			return this.thumbnail
		}
	},
	methods: {
		checkImage: function(value){
			var format = new RegExp('([^\s]+(\\.(jpg|png|gif|pdf))$)', 'i');
			let isImage = format.test(value);
			let img = value;
			if(!isImage){
				img = "/assets/images/article/noimage.png";
			}
			return img;
		}
	}
}

var vFutureEvents = {
	name : "vFutureEvents",
	props: ['fevent'],
	template: 	`<li :class="fevent.extarnal | checkIsInvison">
					<a :href=fevent.link :title="fevent.name | removeHTML">
						<div class="place">
							<p>{{fevent.area | checkArea}}</p>
						</div>
						<div class="content">
							<div class="img"><figure :style="{ 'background-image': 'url( ' + checkImage( fevent.thumbnail ) + ' )' }"></figure></div>
							<div class="txt">
								<div class="name">
									<p class="date">{{fevent.event_start + "," +  fevent.event_end | returnDate}}</p>
									<h4 v-html="fevent.name"></h4>
								</div>
								<p class="description" v-html="fevent.description"></p>
							</div>
						</div>
					</a>
				</li>`,
	filters: {
		removeHTML: function(value){
			return value.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'')
		},
		returnDate: function(value){
			let dates = value.split(",");
			let start = dates[0];
			let end = dates[1];
			let startY = start.split("-")[0];
			let endY = end.split("-")[0];
			let endArry = end.split("-");
			if(start === end){
				return start.split("-").join("/");
			}else{
				if(startY === endY){
					return start.split("-").join("/") + "〜" + endArry[1] + "/" + endArry[2];
				}else{
					if(end == null || end === "null"){
						return start.split("-").join("/") + "〜";
					}else{
						return start.split("-").join("/") + "〜" + end.split("-").join("/");
					}
				}
			}
		},
		checkArea: function(value){
			if(value === "null" || !value){
				return "";
			}else{
				return value;
			}
		},
		checkIsInvison: function(value){
			if(value === 0){
				return "invison";
			}
		}
	},
	computed: {
		thumbnailSRC(){
			return this.thumbnail
		}
	},
	methods: {
		checkImage: function(value){
			var format = new RegExp('([^\s]+(\\.(jpg|png|gif|pdf))$)', 'i');
			let isImage = format.test(value);
			let img = value;
			if(!isImage){
				img = "/assets/images/article/noimage.png";
			}
			return img;
		}
	}
}
