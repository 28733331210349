let eventCalendar;
let calendar = {
	init: function(){
		eventCalendar = new Vue({
			el: "#block_events",
			data: {
				events			: [],
				futureEvents 	: [],
				maxPage		: 0,
				max 		: 0,
				page		: 0,
				each		: 10,
				start 		: 0,
			},
			components: {
				vEvents,
				vFutureEvents
			},
			methods: {
				
			}
		});
		let today 	= new Date();
		let year 	= today.getFullYear();
		let month 	= today.getMonth() + 1;
		//取得の仕方を打ち合わせ後に実装
		setTimeout(function(){
			calendar.getList(0);
		}, 100);
	},
	getList: function(start){
		let url = domain + apiURL.eventcalendar;
		let param = {
			"open": "",
			"page": start
		}
		load.getJson(url, param).done(function(json){
			if(json.allposts === 0){ return false}
			eventCalendar.events 	= [];
			eventCalendar.max 		= json.eventcalendar.length;
			
			let len = start + eventCalendar.each;
			if(len > eventCalendar.max){
				len =  eventCalendar.max;
			}
			
			for(let i = start; i < eventCalendar.max; i++){
				let event = json.eventcalendar[i];
				if(event.open === 1){
					eventCalendar.events.push(event);
				}else{
					eventCalendar.futureEvents.push(event);
				}
			}
			//console.log(eventCalendar.events, eventCalendar.futureEvents)
			if(eventCalendar.events.length === 0){
				$("#block_event_current").remove();
			}
			if(eventCalendar.futureEvents.length === 0){
				$("#block_event_infuture").remove();
			}
			
			//console.log(eventCalendar.futureEvents);
			if($("body").hasClass("disable")){
				$("body").removeClass("disable");
			}
		});
	}
}