var faq = {
	init: function(){
		console.log("faq")
		$(".q").each(function(i){
			$(this).find("a").off().on("click", function(){
				$(".q").eq(i).find("a").toggleClass("active");
				$(".a").eq(i).find(".a_content").animate({
					height: "toggle", opacity: "toggle"
				}, 400);
			});
		});
	}
}