let shops;
let shopList = {
	genre: "",
	area: "",
	initial: "",
	currentPage: 0,
	maxPage: 0,
	allPosts: 0,
	data: [],
	canLoad: true,
	init: function(){
		shops = new Vue({
			el: "#block_shop",
			data: {
				lists		: [],
				genres 		: [],
				areas 		: [],
				initials 	: [],
				maxPage		: 0,
				max 		: 0,
				page		: 0,
				each		: 16
			},
			components: {
				vShop,
				vShopGenres,
				vShopAreas,
				vInitials
			},
			updated: function(){
				let $list = $("#list_shop");
				if( $list.hasClass("disable") ){
					$list.removeClass("disable");
				}
				common.resize();
				common.scrl();
				shopList.nav();
			}
		});
		shopList.getNavs();
		shopList.checkURL();
		$(window).off("popstate").on("popstate", function(){
			shopList.canLoad = false;
			shopList.checkURL();
		});
	},
	changeURL: function(value){
		let url = "";
		if(value != ""){
			url = "/shop/?" + value;
		}else{
			url = "/shop/";
		}
		window.history.pushState(null, null, url);
		shopList.canLoad = false;
		
		this.checkURL();
	},
	checkURL: function(){
		let param 	= location.search.substring(1),
			pair 	= param.split('&');
		//console.log(param);
		shopList.genre = "";
		shopList.area = "";
		shopList.initial = "";
		if(param === ""){
			shopList.disablePost().done(function(){
				shopList.getList(0);
			});
		}else{
			let hasSearch 	= false;
			let searchBy  	= "";
			for(var i = 0; pair[i]; i++) {
				var kv = pair[i].split('=');
				if(kv[0] === "genre"){
					shopList.genre = kv[1];
				}else if( kv[0] === "area" ){
					shopList.area = kv[1];
				}else if(kv[0] === "initial"){
					shopList.initial = kv[1];
				}
			}
			//console.log("genre:", shopList.genre, "area:", shopList.area, "initial:", shopList.initial);
			shopList.disablePost().done(function(){
				shopList.narrow();
			});
		}
		shopList.checkNav();
	},
	getNavs: function(){
		//場所取得
		let $list = $("#list_shop");
		let url = domain + apiURL.shopArea;
		load.getJson(url, {}).done(function (json) {
			//console.log(json);
			for (let i = 0; i < json.length; i++) {
				shops.areas.push(json[i]);
			}
			//console.log(shops.areas);
		});
		//ジャンル取得
		let genreURL = domain + apiURL.shopGenre;
		load.getJson(genreURL, {}).done(function(json){
			for(let i = 0; i < json.length; i++){
				let g = json[i];
				shops.genres.push(g);
			}
		});
		//イニシャル取得
		let initialURL = domain + apiURL.initial;
		load.getJson(initialURL, {}).done(function(json){
			//console.log(json);
			for(let i = 0; i < json.length; i++){
				let g = json[i];
				if(g.allposts != 0){
					let name = g.txt;
					if(g.txt === "roman"){
						name = "英数字";
					}
					let Initial = {
						name: name,
						id: g.txt
					}
					shops.initials.push(Initial);
				}
			}
		});
	},
	getList: function(start){
		let $list = $("#list_shop");
		let shopURL = domain + apiURL.shop;
		load.getJson(shopURL, {}).done(function(json){
			//console.log(json);
			shopList.data = json.shops;
			shops.lists 	= [];
			shops.max 		= json.allposts;
			
			shops.maxPage 	= Math.ceil( shops.max/shops.each );
			let start 		= shopList.currentPage*shops.each;
			let len 		= start + shops.each;
			
			//ページャーのチェック
			shopList.checkPage();
			if(len > shops.max){
				len = shops.max;
			}
			for(let i = start; i < len; i++){
				//console.log(i, json.shops[i])
				shops.lists.push(json.shops[i]);
			}
			//console.log(shops.lists)
			//クッキーに入っているSTARをショップに反映 > apiからstar付きが返ってくる可能性があるので、待ち
			setTimeout(function(){
				common.resize();
				common.scrl();
				if( $list.hasClass("disable") ){
					$list.removeClass("disable");
				}
				shopList.nav();
				star.addStarToShop();
			}, 600);
		});
	},
	checkNav: function(){
		$("#search_area li a").removeClass("active");
		$("#search_genre li a").removeClass("active");
		$("#search_initial li a").removeClass("active");
		if(shopList.area != ""){
			$("#search_area li").each(function(i){
				let $this = $(this);
				if( parseInt( $this.find("a").attr("data-id") ) === parseInt(shopList.area) ){
					$(this).find("a").addClass("active");
				}
			});
		}
		if(shopList.genre != ""){
			$("#search_genre li").each(function(i){
				let $this = $(this);
				if( parseInt( $this.find("a").attr("data-id") ) === parseInt(shopList.genre) ){
					$this.find("a").addClass("active");
				}
			});
		}
		if(shopList.initial != ""){
			$("#search_initial li").each(function(i){
				let $this = $(this);
				if( $this.find("a").attr("data-initial") === decodeURIComponent(shopList.initial) ){
					$this.find("a").addClass("active");
				}
			});
		}
	},
	nav: function(){
		let $noresult = $("#noresult");
		
		$("#search_area li a").off().on("click", function(){
			$("#search_area li a").removeClass("active");
			$(this).addClass("active");
			let id = $(this).attr("data-id");
			shopList.area = id;
			getURL();
			closeSearch(0);
		});
		
		$("#search_genre li a").off().on("click", function(){
			$("#search_genre li a").removeClass("active");
			$(this).addClass("active");
			let idx = $("#search_genre li a").index(this);
			let id = "";
			if( idx === 0 ){
				id = "";
			}else{
				id = $(this).attr("data-id");
			}
			shopList.genre = id;
			getURL();
			closeSearch(1);
		});
		$("#search_initial li a").off().on("click", function(){
			$("#search_initial li a").removeClass("active");
			$(this).addClass("active");
			let id = $(this).attr("data-initial");
			shopList.initial = id;
			getURL();
			closeSearch(2);
		});
		$("#btn_release_sort").off().on("click", function(){
			shopList.area 	 = "";
			shopList.genre 	 = "";
			shopList.initial = "";
			if( $noresult.hasClass("active") ){
				$noresult.removeClass("active");
			}
			$("#search_area li a").removeClass("active");
			$("#search_genre li a").removeClass("active");
			$("#search_initial li a").removeClass("active");
			getURL();
		});
		
		function closeSearch(idx){
			if( $noresult.hasClass("active") ){
				$noresult.removeClass("active");
			}
			let $nav = $("#block_shop .col").eq(idx).find("a.btn_open_search");
			$nav.removeClass("active");
			$(".block_search").eq(idx).animate({
				height: "toggle", opacity: "toggle"
			});
		}
		//load more
		$(".more a").off("click").on("click", function(){
			shopList.loadMore();
		});
		//2021.07.26 added
		function getURL(){
			let param 	= location.search.substring(1),
				pair 	= param.split('&');
			let URL = "";
			let count = 0;
			if(shopList.genre != ""){
				URL += `genre=${shopList.genre}`;
				count++;
			}
			if(shopList.area != ""){
				if(count > 0){
					URL += "&";
				}
				URL += `area=${shopList.area}`;
				count++;
			}
			if(shopList.initial != ""){
				if(count > 0){
					URL += "&";
				}
				URL += `initial=${shopList.initial}`;
				count++;
			}
			shopList.changeURL(URL);
		}
	},
	disablePost: function(){
		var defer = $.Deferred();
		$("#list_shop").addClass("disable");
		setTimeout(function(){
			shops.lists = [];
			$("#list_shop li").remove();
			shopList.currentPage = 0;
			defer.resolve();
		}, 600);
		return defer.promise();	
	},
	narrow: function(){
		let shopURL = domain + apiURL.shop;
		let initial = decodeURIComponent(shopList.initial);
		//console.log(initial, shopList.initial);
		let params = {
			areaid: shopList.area,
			genreid: shopList.genre,
			initial: initial
		}
		shopList.currentPage = 0;
		//console.log(params);
		load.getJson(shopURL, params).done(function(json){
			//console.log(json);
			shopList.data 	= json.shops;
			shops.max 		= json.allposts;
			
			if(shops.max === 0){
				$("#noresult").addClass("active");
			}else{
				if( $("#noresult").hasClass("active") ){
					$("#noresult").removeClass("active");
				}
			}
			shops.maxPage 	= Math.ceil( shops.max/shops.each );
			//-console.log(shopList.data, shops.max, shops.maxPage)
			//ページャーのチェック
			shopList.checkPage();
			
			let start 		= shopList.currentPage*shops.each;
			let len 		= start + shops.each;
			
			if(len > shops.max){
				len = shops.max;
			}
			//console.log(shopList.currentPage, start, len);
			for(let i = start; i < len; i++){
				//console.log(i, json.shops[i])
				shops.lists.push(json.shops[i]);
			}
			//console.log(shops.lists)
			//クッキーに入っているSTARをショップに反映 > apiからstar付きが返ってくる可能性があるので、待ち
			setTimeout(function(){
				shopList.checkNav();
				star.addStarToShop();
			}, 400);
		});
	},
	loadMore: function(){
		shopList.currentPage++;
		//console.log(shopList.currentPage, shops.maxPage);
		if(shopList.currentPage >= shops.maxPage){
			shopList.currentPage = shops.maxPage;
			return false
		}
		let start 	= shopList.currentPage*shops.each;
		let len 	= (shopList.currentPage + 1)*shops.each;
		if(len > shops.max){
			len = shops.max;
		}
		for(let i = start; i < len; i++){
			//console.log(i, json.shops[i])
			shops.lists.push(shopList.data[i]);
		}
		//ページャーのチェック
		shopList.checkPage();
	},
	checkPage: function(){
		//console.log("pageMax:", shops.maxPage, shopList.currentPage);
		if(shops.maxPage === 0){
			$("#shop_list .more").addClass("disable");
		}else if(shopList.currentPage >= shops.maxPage){
			$("#shop_list .more").addClass("disable");
		}else{
			$("#shop_list .more").removeClass("disable");
		}
		shopList.checkNarrow();
	},
	checkNarrow: function(){
		let $btnSort = $("#btn_release_sort");
		shopList.checkNav();
		let count = 0;
		if(shopList.genre != ""){
			count++;	
		}
		if(shopList.area != ""){
			count++;
		}
		if(shopList.initial != ""){
			count++;
		}
		setTimeout(function(){
			if(shopList.currentPage === 0){
				$("#list_shop li").each(function(i){
					let $this = $(this);
					if(i < 8){
						$this.addClass("animate");
					}
				})
			}
			shopList.canLoad = true;
		}, 200);
		if(count === 0){
			if( !$btnSort.hasClass("disable") ){
				$("#btn_release_sort").addClass("disable");
			}
		}else{
			if( $btnSort.hasClass("disable") ){
				$("#btn_release_sort").removeClass("disable");
			}
		}
	}
}