var articleSpots = [];
var article = {
	map: "",
	bound: "",
	markerList: [],
	group: [],
	init: function(){
		article.nav();
		//続きを読む、削除
		if(isSP){
			$(".btn_read_more").remove();
			$(".content_body").animate({
				height: "toggle", opacity: "toggle"
			}, 100);
		}
	},
	nav: function(){
		$(".table_of_contents dd a").off().on("click", function(e){
			e.preventDefault();
			let id = $(this).attr("href");
			//console.log(idx);
			let posY = $(id).offset().top - $("header").height();
			common.scrollToPosition(posY, 1000);
		});
		//続きを読む
		$(".btn_read_more").off().on("click", function(){
			$(this).remove();
			$(".content_body").animate({
				height: "toggle", opacity: "toggle"
			}, 300);
		});
	},
	makeMap: function(){
		let basePos = articleSpots[0];
		article.map = L.map('map_container',
			{
				center: basePos,
				minZoom: 6,//12
				maxZoom: 18,
				zoom: 17,
				zoomControl: false,
				doubleClickZoom: false,
			}
		);
		article.map.scrollWheelZoom.disable();
		
		/*
		L.control.mapCenterCoord({
			position: 'bottomleft'
		}).addTo(article.map);
		*/
		//direction UI
		
		L.control.scale(
			{
				position: 'bottomright',
				imperial: false
			}
		).addTo(article.map);
		//zoomUI
		L.control.zoom(
			{ position: 'bottomright' }
		).addTo(article.map);

		//地理院地図の標準地図タイル
		var gsi =L.tileLayer('https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png', { 
			attribution: "<a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>地理院タイル</a>"
		});
		
		//地理院地図の淡色地図タイル
		var gsipale = L.tileLayer('http://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png', {
			attribution: "<a href='http://portal.cyberjapan.jp/help/termsofuse.html' target='_blank'>地理院タイル</a>"
		});
		
		//オープンストリートマップのタイル
		var osmTile = L.tileLayer('http://tile.openstreetmap.jp/{z}/{x}/{y}.png',{
			attribution: "<a href='http://osm.org/copyright' target='_blank'>OpenStreetMap</a> contributors"
		});
		
		L.TileLayer.Kitten = L.TileLayer.extend({
			getTileUrl: function(coords) {
				////return `http://tile.openstreetmap.jp/${coords.z}/${coords.x}/${coords.y}.png`;
				let defaultMapURL = `http://tile.openstreetmap.jp/${coords.z}/${coords.x}/${coords.y}.png`;
				if(coords.z === 12){
					if(coords.x === 3601 && coords.y === 1629){
						return `/assets/tile/${coords.z}/${coords.x}_${coords.y}.png`;
					}else{
						return defaultMapURL;
					}
				}else if(coords.z === 13){
					if(coords.x === 7202 && coords.y === 3259){
						return `/assets/tile/${coords.z}/${coords.x}_${coords.y}.png`;
					}else{
						return defaultMapURL;
					}
				}else if(coords.z === 14){
					if(coords.x >= 14404 && coords.x <= 14405 && coords.y === 6519){
						return `/assets/tile/${coords.z}/${coords.x}_${coords.y}.png`;
					}else{
						return defaultMapURL;
					}
				}else if(coords.z === 15){
					if(coords.x >= 28809 && coords.x <= 28810 && coords.y >= 13038 && coords.y <= 13039){
						return `/assets/tile/${coords.z}/${coords.x}_${coords.y}.png`;
					}else{
						return defaultMapURL;
					}
				}else if(coords.z === 16){
					if(coords.x >= 57618 && coords.x <= 57621 && coords.y >= 26077 && coords.y <= 26079){
						return `/assets/tile/${coords.z}/${coords.x}_${coords.y}.png`;
					}else{
						return defaultMapURL;
					}
				}else if(coords.z === 17){
					if(coords.x >= 115237 && coords.x <= 115243 && coords.y >= 52155 && coords.y <= 52159){
						return `/assets/tile/${coords.z}/${coords.x}_${coords.y}.png`;
					}else{
						return defaultMapURL;
					}
				}else if(coords.z === 18){
					if(coords.x >= 230474 && coords.x <= 230487 && coords.y >= 104310 && coords.y <= 104320){
						return `/assets/tile/${coords.z}/${coords.x}_${coords.y}.png`;
					}else{
						return defaultMapURL;
					}
				}else{
					return defaultMapURL;
				}
			}
		});
		
		L.tileLayer.kitten = function() {
			return new L.TileLayer.Kitten();
		}
		L.tileLayer.kitten().addTo(article.map);
		
		//baseMapsオブジェクトのプロパティに3つのタイルを設定
		var baseMaps = {
			"地理院地図" 	: gsi,
			"淡色地図" 	: gsipale,
			"オープンストリートマップ"  : osmTile
		};
		//layersコントロールにbaseMapsオブジェクトを設定して地図に追加
		//L.control.layers(baseMaps).addTo(article.map);
		
		//どのマップ画像を使うか選択
		//gsipale.addTo(article.map);
		for(var i = 0; i < articleSpots.length; i++){
			article.addSpot(i);
		}
		//var group = new L.featureGroup(article.group);
		//article.map.fitBounds(group.getBounds());
	},
	addSpot: function(i){
		let latlng = articleSpots[i];
		var mpoint 	= [latlng[0], latlng[1]];
		let url 	= latlng[2];
		let x = 24;
		let y = 33;
		let thisMarker = "";
		//console.log(latlng)
		if(latlng[0] != ""){
			if( $("main").hasClass("eventcalendar") ){
				thisMarker = L.icon({ iconUrl: "/mapicons/icon_default.svg", iconRetinaUrl: "/mapicons/icon_default.svg", iconSize: [x,y], iconAnchor: [x/2, y], popupAnchor: [0, -y],className: "" });
			}else{
				thisMarker = L.icon({ iconUrl: url, iconRetinaUrl: url, iconSize: [x,y], iconAnchor: [x/2, y], popupAnchor: [0, -y],className: "" });
			}
			//let mapMarker = L.marker(mpoint, { icon: thisMarker }).bindPopup( popup1 ).addTo(article.map);
			let mapMarker = L.marker(mpoint, { icon: thisMarker }).addTo(article.map);
			//article.bound.extend(mpoint);
			//article.group.push(mapMarker);
		}else{
			$(".wrap_map").addClass("disable");
			$(".map_more").addClass("disable");
		}
	}
}