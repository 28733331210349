var Slide = {
	current: 0,
	max: 0
}
var slideshows = [];
var slideshowTimer;
var slideshow = {
	duration: 5000,
	init: function(){
		slideshows = [];
		//console.log("slideshow init")
		$(".slideshow").each(function(i){
			let _s = object(Slide);
			_s.current = 0;
			_s.max = $(this).find("li").length;
			slideshows.push(_s);
			$(this).addClass("active0");
			//console.log(_s.max);
			if( _s.max > 1 ){
				let navHTML = '';
				for(var j = 0; j < _s.max; j++){
					if(j === 0){
						navHTML += `<li><a class="active" href="javascript:void(0);" title="${i}"><span>${i}</span></a></li>`;
					}else{
						navHTML += `<li><a href="javascript:void(0);" title="${i}"><span>${i}</span></a></li>`;
					}
				}
				$(".nav_slideshow").eq(i).find("ul").html(navHTML);
				
			}else{
				$(".nav_slideshow").eq(i).remove();
			}
		});

		if(slideshows.length > 0){
			slideshow.start();
			slideshow.nav();
		}
	},
	start: function(){
		clearInterval(slideshowTimer);
		slideshowTimer = setInterval(function(){
			for(var i = 0; i < slideshows.length; i++){
				slideshow.inc(i);
			}
		}, slideshow.duration);
	},
	nav: function(){
		$(".nav_slideshow li a").off().on("click", function(){
			let idx = $(".nav_slideshow").index($(this).parent().parent().parent());
			let current = $(".nav_slideshow").eq(idx).find("li a").index( $(this) );
			slideshows[idx].current = current;
			slideshow.change(idx);
		});
	},
	inc: function(i){
		slideshows[i].current++;
		if(slideshows[i].current >= slideshows[i].max){
			slideshows[i].current = 0;
		}
		slideshow.change(i);
	},
	dec: function(){

	},
	change: function(i){
		let $slide = $(".slideshow").eq(i);
		$slide.removeClass(function(index, className) {
			return (className.match(/\bactive\S+/g) || []).join(' ');
		});
		$slide.addClass("active" + slideshows[i].current);
		
		let $nav = $(".nav_slideshow").eq(i);
		$nav.find("li a").removeClass("active");
		$nav.find("li").eq(slideshows[i].current).find("a").addClass("active");
		slideshow.start();
	}
}