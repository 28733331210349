var sitemap = {
	init: function(){
		console.log("sitemap")
		//CATEGORY
		let url = domain + apiURL.category;
		let categoryHTML = `<li><a href="/">HOME</a></li>`;
		load.getJson(url, {}).done(function (json) {
			for (var i = 0; i < json.length; i++) {
				let c = json[i];
				console.log(c);
				categoryHTML += `<li><a href="/?category=${c.id}">${c.initial} | ${c.kana}</a></li>`;
			}
			$("#list_category ul").html(categoryHTML);
		});
		
		/*
		let areaUrl = domain + apiURL.areazone;
		let areaHTML = "";
		load.getJson(areaUrl, {}).done(function (json) {
			for (var i = 0; i < json.length; i++) {
				let a = json[i];
				areaHTML += `<dd><a href="/?area=${a.id}">${a.name}</a></dd>`;
			}
			$("#list_area dl").append(areaHTML);
		});
		*/
		//記事
		let postUrl = domain + apiURL.post;
		let param = {
			categoryid 	: home.category,
			tagid 		: home.tag,
			cityid 		: home.cityid,
			page		: home.page,
			favorite	: false,
			latlng		: home.laglng
		};
		let postHTML = "";
		load.getJson(postUrl, param).done(function (json) {
			postHTML += ``;
			for(var a = 0; a < json.post.length; a++){
				let post = json.post[a];
				let date = post.date;
				date = date.split("-").join("/");
				let author = "";
				if(!post.author){
					
				}else{
					author = `<dt>${post.author}</dt>`;
				}
				let belongs = "";
				if(!post.author_belongs){
					
				}else{
					belongs = `<dd>${post.author_belongs}</dd>`
				}
				console.log(post)
				if(post.external === 1){
					postHTML += `<li><a href="${post.link}" title="${post.title}">`;
				}else{
					postHTML += `<li><a class="invison" href="${post.link}" title="${post.title}">`
				}
				postHTML += `<h3>${post.title}</h3>
								<div class="info">
									<dl>
										${author}
										${belongs}
									</dl>
								</div>
								<p class="date">${post.date}</p>
							</a>
						</li>`;
			}
			$("#section_posts .list_posts").html(postHTML);
		});
	}
}