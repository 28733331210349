var area = {
	init: function(){
		articleSpots = [];
		
		area.loadShop();
		area.slideshow();
	},
	loadShop: function(){
		let shopURL = domain + apiURL.shop;
		let params = {
			areaid: $("body").attr("area-id"),
			genreid: "",
			initial: ""
		}
		load.getJson(shopURL, params).done(function(json){
			//console.log(json);
			let HTML = "";
			for(var i = 0; i < json.shops.length; i++){
				let s = json.shops[i];
				let genre = s.genre;
				let genreHTML = "";
				if(genre.length > 0){
					for(var j = 0; j < genre.length; j++){
						if(j === genre.length-1){
							genreHTML += genre[j];
						}else{
							genreHTML += genre[j] + ",";
						}
					}
				}
				let spot = [s.latlng[0], s.latlng[1], s.icon];
				if(!spot[0]){
					
				}else{
					articleSpots.push( spot );
				}
				
				let category = "";
				if(!s.subcategory){
					
				}else{
					category = `<p class="category">${s.subcategory}</p>`
				}
				HTML += `<li>
							<a href="${s.link}" title="${s.name}">
								<div class="img_shop"><figure style="background-image: url(${s.image});"></figure></div>
								<div class="txt">
									<h5>${s.name}</h5>
									${category}
									<h6>${s.category}</h6>
								</div>
							</a>
							<a class="btn_star" href="javascript:void(0);" title="STAR" data-id="${s.id}"><span>STAR</span></a>
						</li>`;
			}
			$("#shops_in_area ul").html(HTML);
			article.makeMap();
			setTimeout(function(){
				common.resize();
				area.nav();
			}, 100);
		});
	},
	nav: function(){
		$("a.btn_star").off().on("click", function(){
			let value = $(this).attr("data-id");
			console.log(value);
			let $this = $(`a.btn_star[data-id="${value}"]`);
			if( $this.hasClass("active") ){
				$this.removeClass("active");
				star.removeStar(value);
			}else{
				$this.addClass("active");
				star.checkStar(value);
			}
		});
	},
	slideshow: function(){
		let count = 0;
		let max = 0;
		let slideTimer;
		
		if( $(".area_slideshow").length > 0 ){
			let navHTML = "";
			$(".area_slideshow li").each(function(i){
				if(i === 0){
					navHTML += `<li><a class="active" href="javascript:void(0);" title="${i}">${i}</a></li>`
				}else{
					navHTML += `<li><a href="javascript:void(0);" title="${i}">${i}</a></li>`
				}
			});
			$(".nav_slideshow").html(navHTML);
			$(".nav_slideshow li a").off().on("click", function(){
				count = $(".nav_slideshow li a").index(this);
				clearInterval(slideTimer);
				change();
			});
			max = $(".area_slideshow li").length;
			if( max > 1 ){
				slideTimer = setInterval(function(){
					inc();
				},3000);
			}
		}
		function inc(){
			count++;
			if(count >= max){
				count = 0;
			}
			change();
		}
		function change(){
			clearInterval(slideTimer);
			$(".area_slideshow li").removeClass("active");
			$(".area_slideshow li").eq(count).addClass("active");
			$(".nav_slideshow li a").removeClass("active");
			$(".nav_slideshow li ").eq(count).find("a").addClass("active");
			slideTimer = setInterval(function(){
				inc();
			},3000);
		}
	}
}