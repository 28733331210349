var contact = {
	canSend: false,
	init: function(){
		contact.check();
		$('dl.require dd input').on("change", function(){
			contact.check();
		});
		$('dl.require dd select').on("change", function(){
			contact.check();
		});
		$('dl.require dd textarea').on("change", function(){
			contact.check();
		});
		$('#btn_confirm').off().on("click", function(e){
			e.preventDefault();
			if(contact.canSend){
				let email = $(`[name="email"]`).val();
				let confirmEmail = $(`[name="email_confirm"]`).val();
				console.log(email, confirmEmail)
				if(email === confirmEmail){
					let url = "/contact/form_send_get.php";
					let param = {
						"name": $('input[name="name"]').val(),
						"company": $('input[name="company"]').val(),
						"tel": $('input[name="tel"]').val(),
						"email":  $('input[name="email"]').val(),
						"email_confirm":  $('input[name="email"]').val(),
						"zip1": $('input[name="zip1"]').val(),
						"zip2": $('input[name="zip2"]').val(),
						"pref": $('select[name="pref"] :selected').val(),
						"addr1": $('input[name="addr1"]').val(),
						"addr2": $('input[name="addr2"]').val(),
						"content": $("textarea").val()
					}
					load.postJson(url, param).done(function(json){
						console.log(json);
						location.href = "/contact/confirm.php";
					});
				}else{
					$(".confirm").addClass("is_different");
					if( $("dl.confirm").length > 0 ){
						let posY = $("dl.confirm").offset().top - $("header").height() - 20;
						common.scrollToPosition(posY, 200);
					}
				}
			}
		});
		$("#btn_send").off().on("click", function(e){
			e.preventDefault();
			
			let url = "/contact/send_get.php";
			load.postJson(url, {}).done(function(json){
				console.log(json);
				location.href = "/contact/thanks.php";
			});
		});
	},
	check: function(){
		let arry = [];
		let count = 0;
		$("dl.require").each(function(i){
			let $this = $(this),
				val = "";
			if( $(this).hasClass("address") ){
				$this.find("input").each(function(j){
					let v = $(this).val();
					if( v != "" ){
						arry[count] = 1;
					}else{
						arry[count] = 0;
					}
					count++;
				});
			}else{
				if( $(this).find("textarea").length > 0 ){
					val = $(this).find("textarea").val();
				}else{
					val = $(this).find("input").val();
				}
				if( val != "" ){
					if(!val){
						arry[count] = 0;
					}else{
						arry[count] = 1;
					}
				}else{
					arry[count] = 0;
				}
				count++;
			}
			
		});
		let selectVal = $("dl.require.address").find("select option:selected").val();
		if(selectVal != ""){
			arry[arry.length] = 1;
		}else{
			arry[arry.length] = 0;
		}
		//console.log(arry);
		if(arry.indexOf(0) > -1){
			if( $(".wrap_submit").hasClass("active") ){
				contact.canSend = false;
				$(".wrap_submit").removeClass("active");
			}
		}else{
			//項目が埋まっている
			if( !$(".wrap_submit").hasClass("active") ){
				$(".wrap_submit").addClass("active");
				contact.canSend = true;
			}
		}
	}
}